:root {
  --color-text: #190000;
  --color-romance: #fffdfa;
  --color-blue: #3a65b0;
}

:root {
  --color-hover: var(--color-text);
}

html, body {
  height: 100%;
}

body {
  color: var(--color-text);
  background: var(--color-romance);
  overscroll-behavior: none;
}

@media (max-width: 767px) {
  #__next {
    height: 100%;
    overflow: scroll;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.ReactModal__Overlay {
  z-index: 500;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  user-select: none;
}

textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  appearance: none;
  -webkit-appearance: none;
}

button,
button:focus {
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

svg {
  transition: fill 0.2s cubic-bezier(0, 0, 0.15, 1),
    stroke 0.2s cubic-bezier(0, 0, 0.15, 1),
    color 0.2s cubic-bezier(0, 0, 0.15, 1);
}
svg path {
  transition: fill 0.2s cubic-bezier(0, 0, 0.15, 1),
    stroke 0.2s cubic-bezier(0, 0, 0.15, 1),
    color 0.2s cubic-bezier(0, 0, 0.15, 1);
}
