/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.modalOverlay {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.pseudoModal {
  z-index: 10000;
}

.ReactModalPortal * {
  outline: none;
}

.modalContent {
  bottom: 0;
  top: 0;
  position: fixed;
  left: 50%;
  overflow: hidden;
  right: auto;
  background-color: var(--color-romance);
  width: 50%;
  height: 100%;
  padding: 0;
  outline: none;

  @media (max-width: 767px) {
    left: 0;
    width: 100%;
  }
}

.ReactModalPortal .ReactModal__Content {
  transform: translate3d(110vh, 0, 0);
  will-change: transofrm;
}

.ReactModalPortal .ReactModal__Content--after-open {
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s cubic-bezier(0, 0, 0.15, 1) 0.01ms;
}

.ReactModalPortal .ReactModal__Content--before-close {
  transform: translate3d(150vh, 0, 0);
  transition: transform 0.4s cubic-bezier(0, 0, 0.15, 1) 0.01ms;
}

/** React Modal **/
.ReactModal__Body--open {
  overflow: hidden !important;
  touch-action: none;
  overscroll-behavior: none;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.ReactModal__Body--open #__next {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: rgba(25, 0, 0, 0.2);
}

.ReactModal__Overlay--after-open {
  transition: background-color 0.2s ease 0.01ms;
  background-color: rgba(25, 0, 0, 0.3);
}

.ReactModal__Overlay--before-close {
  transition: none;
  background-color: rgba(25, 0, 0, 0);
}
/** end React Modal **/
