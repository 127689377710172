.header {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 15;

  background: var(--color-romance);
  border-bottom: 1px solid var(--color-text);
  height: 96rem;

  @media (max-width: 767px) {
    display: none;
  }
}

.pattern {
  fill: var(--color-text);
  position: absolute;
  left: 50%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.patternEng {
  left: 50%;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: background 0.2s cubic-bezier(0, 0, 0.15, 1);
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  width: 528rem;

  & svg {
    fill: var(--color-text);
    width: 100%;
    height: 100%;
    transition: fill 0.2s cubic-bezier(0, 0, 0.15, 1);
  }
}

.logoEng {
  composes: logo;
  left: 50%;
}


@media (max-width: 1260px) {
  .patternEng {
    left: 50%;
  }
}
