.hide-medium {
    @media (max-width: 768px) {
        display: none!important;
        visibility: hidden;
    }
}

.visible-medium {
    @media (min-width: 769px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 768px) {
        display: inherit;
        visibility: inherit;
    }
}
