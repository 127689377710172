.ratio-outer {
  height: 0;
  overflow: hidden;

  position: relative;
}

.ratio-outer > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
