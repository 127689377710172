.header {
  overflow: hidden;
  background: var(--color-romance);
  z-index: 15;
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--color-text);
  height: 18vw;

  @media (min-width: 360px) {
    height: 65px;
  }
  @media (min-width: 768px) {
    display: none;
  }
}

.pattern {
  fill: var(--color-text);
  position: absolute;
  height: 100%;
  left: calc(50% - 1rem);
  top: 50%;
  transform: translate(-50%, -50%);
}

.patternEng {
   left: 50%;
 }

.logo {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  overflow: hidden;
  width: 360px;
  z-index: 100;

  & svg {
    fill: var(--color-text);
    width: 100%;
    height: 100%;
  }
}
